import { desktopSize } from '../../../const/tokens';
import { fetchAnalytics } from '../../../utils/fetchAnalytics';

export function creteLandingIntersectionObserver(): IntersectionObserver {
    const config = {
        root: null,
        rootMargin: window.innerWidth < desktopSize ? '0px' : '-100px',
        threshold: 0.7,
    };

    return new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            const elementId = entry.target.id;

            if (entry.isIntersecting && elementId) {
                fetchAnalytics().then((analyticsInstance) =>
                    analyticsInstance.view({
                        eventAction: 'Landing:View',
                        eventCategory: 'landing',
                        id: elementId,
                    }),
                );
            }
        });
    }, config);
}
