import React, { useRef, useState, MouseEvent, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { mediaQuery } from '@salutejs/plasma-b2c';
import { white } from '@salutejs/plasma-tokens-b2c';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { Modal } from '../../components/Modal/Modal';
import { ModalBody } from '../../components/Modal/ModalBody';
import { AdaptiveButton } from '../../components/Button/AdaptiveButton';
import { desktopSize, media } from '../../const/tokens';
import { withCdnUrl } from '../../utils/static';
import { Title } from '../../components/Title/Title';
import { Locale } from '../../typings/locale';

import { Video } from './Video';
import { StyledVideoContainer, VideoPreviewCard } from './VideoPreviewCard';
import { scrollToSignUp } from './utils/signUp';

const containerPadding = `calc(50vw - ${desktopSize / 2}px + 96px)`;

const StyledButton = styled(AdaptiveButton)`
    margin-left: 40px;

    ${media.mobile} {
        width: calc(100% - 50px);
        margin-bottom: 40px;
    }
`;

const StyledMobileButton = styled(StyledButton)`
    display: none;

    ${media.mobile} {
        display: block;
    }
`;

const StyledTitle = styled.div`
    display: flex;
    align-items: center;
    margin-top: 112px;
    margin-bottom: 33px;
    align-self: flex-start;
    padding-left: ${containerPadding};
    padding-right: ${containerPadding};

    ${media.tablet} {
        margin-top: 80px;
        margin-bottom: 16px;
        padding: 0 50px;
    }

    ${media.mobile} {
        margin-top: 40px;
        margin-bottom: 45px;
        padding: 0 25px;
    }
`;

const StyledTopButton = styled(StyledButton)`
    ${media.mobile} {
        display: none !important;
    }
`;

const StyledContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;

    ${media.mobile} {
        & ${StyledButton} {
            display: block;
            margin: 34px auto;
        }
    }
`;

const StyledVideoList = styled.div<{ isActiveScroll: boolean }>`
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: ${containerPadding};
    padding-right: ${containerPadding};
    white-space: nowrap;

    /* stylelint-disable */

    & ${StyledVideoContainer} {
        cursor: ${({ isActiveScroll }) => (isActiveScroll ? 'grabbing' : 'pointer')};
    }

    ${media.tablet} {
        padding: 0 50px;
    }

    ${media.mobile} {
        padding: 0 25px;
    }
    /* stylelint-enable */
`;

const StyledVideoCard = styled(VideoPreviewCard)`
    margin-right: 24px;
    display: inline-block;

    /* stylelint-disable */
    ${media.tablet} {
        margin-right: 16px;
    }

    ${media.mobile} {
        margin-right: 16px;
    }
    /* stylelint-enable */

    &:last-child {
        margin-right: 0;
    }
`;

const StyledTitleText = styled(Title)`
    color: ${white};
    letter-spacing: -0.01em;
`;

const StyledVideo = styled(Video)`
    width: 90vw;
    border-radius: 20px;

    ${mediaQuery('XL')(css`
        width: 75vw;
    `)}

    ${mediaQuery('XXL')(css`
        width: 75vw;
    `)}
`;

export const VideoListSection: React.FC = () => {
    const { t } = useTranslation('index');
    const router = useRouter();
    const isRu = router.locale === Locale.Ru;
    const [isActiveScroll, setActiveScroll] = useState(false);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [scrollX, setScrollX] = useState(0);
    const [currentVideoUrl, setCurrentVideoUrl] = useState<string | undefined>();
    const [hasMoved, setHasMoved] = useState(false);
    const scrollAreaRef = useRef<HTMLDivElement>(null);

    const onMouseDown = useCallback((e: MouseEvent) => {
        const offsetLeft = scrollAreaRef.current?.offsetLeft || 0;
        const scrollLeft = scrollAreaRef.current?.scrollLeft || 0;

        setActiveScroll(true);
        setScrollX(e.pageX - offsetLeft);
        setScrollLeft(scrollLeft);
    }, []);

    const onMouseUp = useCallback(() => {
        setActiveScroll(false);
        setHasMoved(false);
    }, []);

    const onMouseLeave = useCallback(() => {
        setActiveScroll(false);
    }, []);

    const onMouseMove = useCallback(
        (e: MouseEvent) => {
            if (!isActiveScroll) {
                return;
            }

            setHasMoved(true);
            e.preventDefault();
            const offsetLeft = scrollAreaRef.current?.offsetLeft || 0;
            const x = e.pageX - offsetLeft;
            const moveOffset = x - scrollX;

            if (scrollAreaRef.current) {
                scrollAreaRef.current.scrollLeft = scrollLeft - moveOffset;
            }
        },
        [isActiveScroll, scrollLeft, scrollX],
    );

    const onSetCurrentVideoUrl = useCallback(
        (url: string) => {
            if (hasMoved) {
                return;
            }

            setCurrentVideoUrl(url);
        },
        [hasMoved],
    );

    return (
        <StyledContainer id="landingVideoList">
            <StyledTitle>
                <StyledTitleText>{t('Как это выглядит?')}</StyledTitleText>
                <StyledTopButton onClick={scrollToSignUp}>{t('Попробовать')}</StyledTopButton>
            </StyledTitle>

            <StyledVideoList
                ref={scrollAreaRef}
                isActiveScroll={isActiveScroll}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseLeave={onMouseLeave}
                onMouseMove={onMouseMove}
            >
                <StyledVideoCard
                    preview={withCdnUrl(isRu ? 'images/videoListPreview1.jpg' : 'images/videoListPreview1En.png')}
                    url={withCdnUrl(isRu ? 'video/videoList1.mov' : 'video/videoList1En.mp4')}
                    title={t('Новость')}
                    onClick={onSetCurrentVideoUrl}
                />
                <StyledVideoCard
                    preview={withCdnUrl(isRu ? 'images/videoListPreview2.jpg' : 'images/videoListPreview2En.png')}
                    url={withCdnUrl(isRu ? 'video/videoList2.mov' : 'video/videoList2En.mp4')}
                    title={t('Презентация товара')}
                    onClick={onSetCurrentVideoUrl}
                />
                <StyledVideoCard
                    preview={withCdnUrl(isRu ? 'images/videoListPreview3.jpg' : 'images/videoListPreview3En.png')}
                    url={withCdnUrl(isRu ? 'video/videoList3.mov' : 'video/videoList3En.mp4')}
                    title={t('Обучающее видео')}
                    onClick={onSetCurrentVideoUrl}
                />
            </StyledVideoList>

            <StyledMobileButton onClick={scrollToSignUp}>{t('Попробовать')}</StyledMobileButton>

            <Modal
                width="75vw"
                open={Boolean(currentVideoUrl)}
                onClose={() => setCurrentVideoUrl(undefined)}
                closePadding
            >
                <ModalBody>{currentVideoUrl && <StyledVideo url={currentVideoUrl} autoPlay />}</ModalBody>
            </Modal>
        </StyledContainer>
    );
};
