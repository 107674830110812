import React, { useCallback } from 'react';
import styled from 'styled-components';
import { backgroundPrimary, black, fontWeights } from '@salutejs/plasma-tokens-b2c';

import { media } from '../../const/tokens';
import { AdaptiveButton } from '../../components/Button/AdaptiveButton';

interface StyledButtonProps {
    active: boolean;
}

const StyledButton = styled(AdaptiveButton)<StyledButtonProps>`
    background-color: ${({ active }) => (active ? '#3F81FD' : black)};
    border-radius: 40px;
    margin-right: 8px;
    margin-bottom: 8px;

    ${media.tablet} {
        background: ${backgroundPrimary};
        border-radius: 40px;
        font-weight: ${fontWeights.Medium};
    }

    ${media.mobile} {
        height: 40px;
        font-size: 14px;
        line-height: 18px;
        border-radius: 40px;
        padding: 0 12px;
        background: ${backgroundPrimary};
        font-weight: ${fontWeights.Medium};
    }
`;

interface GestureButtonProps {
    label: string;
    value: string;
    activeValue?: string;
    onClick(value: string | undefined): void;
}

export const GestureButton: React.FC<GestureButtonProps> = ({ label, value, activeValue, onClick }) => {
    const click = useCallback(() => onClick(activeValue === value ? undefined : value), [activeValue, onClick, value]);

    return (
        <StyledButton data-e2e="gestureButton" active={activeValue === value} onClick={click}>
            {label}
        </StyledButton>
    );
};
