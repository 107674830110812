import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { buttonWhite } from '@salutejs/plasma-tokens-b2c';
import { media } from 'const/tokens';
import { Title } from 'components/Title/Title';
import { Text } from 'components/Text/Text';
import { CharacterPreview, HorSectionProps } from 'components/Landing/CharacterPreview';
import { VoiceButton } from 'components/Landing/VoiceButton';
import { withCdnUrl } from 'utils/static';

const StyledContainer = styled.section`
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    background: #092793;
    overflow: hidden;
    transition: width 0.3s linear;

    ${media.tablet} {
        position: relative;
        right: auto;
        top: auto;
        height: auto;
        padding: 80px 0 80px 50px;
    }

    ${media.mobile} {
        position: relative;
        right: auto;
        top: auto;
        height: auto;
        padding: 46px 0 40px;
    }
`;

const StyledContent = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    margin: 0 auto;
    height: 100%;

    ${media.tablet} {
        position: relative;
        right: auto;
        top: auto;
        width: auto;
        height: auto;
        display: flex;
        flex-flow: row;
    }

    ${media.mobile} {
        position: relative;
        right: auto;
        top: auto;
        width: auto;
        height: auto;
    }
`;

const StyledDescriptionBlock = styled.div`
    display: flex;
    flex-flow: column;
    max-width: 774px;
    height: 100%;
    margin-left: calc(50% - 255px + 108px);
    justify-content: center;
    transition: opacity 0.2s linear;

    ${media.tablet} {
        margin-left: 0;
        padding-left: 40px;
        width: calc(100% - 241px);
        height: auto;
    }

    ${media.mobile} {
        margin-left: 0;
    }
`;

const StyledTitle = styled(Title)`
    color: ${buttonWhite};
    margin-bottom: 8px;
    margin-top: -56px;

    ${media.tablet} {
        margin-top: 0;
        padding-right: 50px;
    }

    ${media.mobile} {
        margin-top: 0;
        padding: 0 24px;
    }
`;

const StyledDescription = styled(Text)`
    color: ${buttonWhite};
    max-width: 739px;
    margin-bottom: 24px;

    ${media.tablet} {
        padding-right: 50px;
    }

    ${media.mobile} {
        padding: 0 24px;
    }
`;

const StyledButtonsContainer = styled.div`
    display: flex;
    flex-flow: row;
    max-width: 100%;
    overflow-x: auto;

    ${media.tablet} {
        padding-right: 50px;
    }

    ${media.mobile} {
        padding: 0 24px;
    }
`;

const StyledPreview = styled(CharacterPreview)`
    position: absolute;
    right: calc(50% + 255px);
    top: 50%;
    transform: translate(0, -50%);

    ${media.tablet} {
        position: relative;
        right: auto;
        top: auto;
        transform: none;
    }

    ${media.mobile} {
        position: relative;
        right: auto;
        top: auto;
        transform: none;
        margin: 0 auto 24px;
    }
`;

export const VoiceSection: React.FC<HorSectionProps> = ({ progress = 0 }) => {
    const { t } = useTranslation('index');
    const [currentPreview, setCurrentPreview] = useState<string | undefined>();

    const pause = useCallback(() => setCurrentPreview(undefined), []);

    return (
        <StyledContainer style={{ width: `${progress}%` }} id="landingVoice">
            <StyledContent>
                <StyledPreview
                    videoPreview={currentPreview}
                    onPause={pause}
                    onClick={pause}
                    preview="images/elena-preview.jpg"
                />
                <StyledDescriptionBlock style={{ opacity: progress / 100 }}>
                    <StyledTitle>{t('Разные голоса')}</StyledTitle>

                    <StyledDescription>{t('Выбирайте стиль речи, подходящий целям вашего видео.')}</StyledDescription>

                    <StyledButtonsContainer>
                        <VoiceButton
                            label={t('Нейтральная')}
                            value={withCdnUrl('video/elena-voice-example.mp4')}
                            activeValue={currentPreview}
                            onClick={setCurrentPreview}
                        />
                        <VoiceButton
                            label={t('Добрая')}
                            value={withCdnUrl('video/borisenko-voice-example.mp4')}
                            activeValue={currentPreview}
                            onClick={setCurrentPreview}
                        />
                        <VoiceButton
                            label={t('Деловая')}
                            value={withCdnUrl('video/mayorova-voice-example.mp4')}
                            activeValue={currentPreview}
                            onClick={setCurrentPreview}
                        />
                    </StyledButtonsContainer>
                </StyledDescriptionBlock>
            </StyledContent>
        </StyledContainer>
    );
};
