import { useCallback, useRef } from 'react';

import { useWindowEvent } from './useWindowEvent';

export const useScroll = (
    callback: (event: Event, lastScrollPosition: number) => void,
    options?: boolean | AddEventListenerOptions,
): void => {
    const lastScroll = useRef<number>(document.getElementsByTagName('html')[0].scrollTop);

    const listener = useCallback(
        (event) => {
            callback(event, lastScroll.current);
            lastScroll.current = document.getElementsByTagName('html')[0].scrollTop;
        },
        [callback],
    );

    useWindowEvent('scroll', listener, options);
};
