import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { useScroll } from '../../hooks/useScroll';
import { isDesktop, media } from '../../const/tokens';
import { withCdnUrl } from '../../utils/static';
import { useWindowResize } from '../../hooks/useWindowResize';

import { getVideoFrames } from './utils/getVideoFrames';

const width = 727;
const height = 682;
const framesCount = 120;
const headerHeight = 112;

interface StyledContainerProps {
    fixed: boolean;
}

const StyledContainer = styled.div<StyledContainerProps>`
    position: absolute;
    transform: translate(0, 100vh);
    top: calc(50% - ${(height + headerHeight) / 2}px);
    right: calc(50% + 91px);
    width: ${width}px;
    height: ${height}px;
    max-width: 100%;

    ${({ fixed }) =>
        fixed &&
        css`
            position: fixed;
            transform: none;
            top: calc(50% - ${height / 2}px);
        `}

    ${media.tablet} {
        transform: none !important;
        position: relative;
        top: auto;
        right: auto;
        width: auto;
        height: auto;

        &::after {
            display: none;
        }
    }

    ${media.mobile} {
        transform: none !important;
        position: relative;
        top: auto;
        right: auto;
        margin: 0 auto;
        width: auto;
        height: auto;

        &::after {
            display: none;
        }
    }

    &::after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 15px;
        background-image: linear-gradient(transparent, #000);
    }
`;

const StyledCanvas = styled.canvas`
    ${media.tablet} {
        display: none;
    }

    ${media.mobile} {
        display: none;
    }
`;

const StyledMobilePreview = styled.video`
    display: none;
    width: 380px;
    height: 360px;
    object-position: bottom;

    ${media.tablet} {
        display: block;
        margin-left: -21px;
        margin-right: -30px;
    }

    ${media.mobile} {
        display: block;
        height: 273px;
        max-width: 100%;
    }
`;

const getScrollRatio = (): number => document.getElementsByTagName('html')[0].scrollTop / window.innerHeight;
const getRoundedRatio = (value: number): number => Math.round(value * framesCount);

export const Character3DPreview: React.FC = () => {
    const [fixed, setFixed] = useState<boolean>(isDesktop() && getScrollRatio() <= 1);
    const uploadStartedRef = useRef<boolean>(false);
    const previewRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const framesRef = useRef<HTMLImageElement[]>([]);

    const drawFrame = useCallback(
        (frame: HTMLImageElement) =>
            requestAnimationFrame(() => canvasRef.current?.getContext('2d')?.drawImage(frame, 0, 0)),
        [],
    );

    const startUpload = useCallback(() => {
        if (uploadStartedRef.current || !isDesktop()) {
            return;
        }

        uploadStartedRef.current = true;
        getVideoFrames('/video/elena-3d-preview.mp4', framesCount, width, height, (frames) => {
            const roundedRatio = getRoundedRatio(getScrollRatio());

            if (framesRef.current.length === 0 || (isDesktop() && framesRef.current.length < roundedRatio)) {
                const frame = frames[roundedRatio] || frames[frames.length - 1];
                frame.onload = () => drawFrame(frame);
            }

            framesRef.current = frames;
        });
    }, [drawFrame]);

    const scroll = useCallback(() => {
        const scrollRatio = getScrollRatio();
        const roundedRatio = getRoundedRatio(scrollRatio);
        const frame = framesRef.current[roundedRatio] || framesRef.current[framesRef.current.length - 1];
        const animationEndBorderRatio = 1;
        const fixedPositionEndBorderRatio = 3;
        const newFixedState = isDesktop() && scrollRatio <= fixedPositionEndBorderRatio;

        setFixed(newFixedState);

        if (isDesktop() && scrollRatio <= animationEndBorderRatio && frame) {
            drawFrame(frame);
        }
    }, [drawFrame]);

    const resize = useCallback(() => {
        startUpload();
        scroll();
    }, [scroll, startUpload]);

    useWindowResize(resize);

    useScroll(scroll);

    useEffect(() => {
        startUpload();
    }, [drawFrame, startUpload]);

    return (
        <StyledContainer ref={previewRef} fixed={fixed}>
            <StyledMobilePreview src={withCdnUrl('video/landingPreviewMobile.mov')} autoPlay loop playsInline muted />
            <StyledCanvas ref={canvasRef} width={width} height={height} />
        </StyledContainer>
    );
};
