import { canUseDOM } from '../utils/canUseDom';

import { useBodyEvent } from './useBodyEvent';

type Listener = EventListenerOrEventListenerObject | ((event: CustomEvent) => void);

export const useMouseWheel = (callback: Listener, options?: boolean | AddEventListenerOptions): void => {
    const eventName = canUseDOM() && 'onmousewheel' in document ? 'mousewheel' : 'wheel';
    useBodyEvent(eventName, callback, options);
};
