async function getVideoFrame(
    videoElement: HTMLVideoElement,
    frameTime: number,
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
): Promise<string> {
    const seekPromise = new Promise((resolve) => videoElement.addEventListener('seeked', resolve));
    videoElement.currentTime = frameTime;
    await seekPromise;
    context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

    return canvas.toDataURL();
}

export async function getVideoFrames(
    url: string,
    framesCount: number,
    width: number,
    height: number,
    onUpdate: (frames: HTMLImageElement[]) => void,
): Promise<HTMLImageElement[]> {
    const frames: HTMLImageElement[] = [];
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const videoElement = document.createElement('video');
    const canPlayPromise = new Promise((resolve) => videoElement.addEventListener('canplay', resolve));
    const loadMetadataPromise = new Promise((resolve) => videoElement.addEventListener('loadedmetadata', resolve));

    canvas.width = width;
    canvas.height = height;
    videoElement.autoplay = true;
    videoElement.src = url;

    await canPlayPromise;
    await loadMetadataPromise;

    if (!context) {
        throw new Error('Your browser does not support canvas 2d');
    }

    for (let i = 0; i < framesCount; i++) {
        const frameTime = (videoElement.duration / framesCount) * i;
        const image = new Image();
        // eslint-disable-next-line no-await-in-loop
        image.src = await getVideoFrame(videoElement, frameTime, canvas, context);
        frames[i] = image;
        onUpdate(frames);
    }

    return frames;
}
