import React from 'react';
import styled from 'styled-components';
import { white } from '@salutejs/plasma-tokens-b2c';
import { useTranslation } from 'next-i18next';

import { Icon } from '../../components/Icon/Icon';
import { media } from '../../const/tokens';
import { CharacterPreview, HorSectionProps } from '../../components/Landing/CharacterPreview';

const StyledContainer = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    background: #000;
    overflow: hidden;
    transition: width 0.3s linear;

    ${media.tablet} {
        position: relative;
        right: auto;
        top: auto;
        height: auto;
    }

    ${media.mobile} {
        position: relative;
        right: auto;
        top: auto;
        height: auto;
    }
`;

const StyledContent = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    margin: 0 auto;
    height: 100%;

    ${media.tablet} {
        position: relative;
        right: auto;
        top: auto;
        height: auto;
        width: auto;
        padding: 94px 0 80px;
    }

    ${media.mobile} {
        position: relative;
        right: auto;
        top: auto;
        height: auto;
        width: auto;
        padding: 40px 0;
    }
`;

const StyledText = styled.div`
    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -150px;
    font-size: 200px;
    font-weight: 600;
    line-height: 300px;
    letter-spacing: -0.01em;
    color: ${white};
    transition: transform 0.2s linear;

    ${media.tablet} {
        position: relative;
        left: auto;
        top: auto;
        margin-left: 0 !important;
        margin-top: 0;
        transform: none !important;
        font-size: 160px;
        line-height: 160px;
        letter-spacing: -0.025em;
        text-align: center;
    }

    ${media.mobile} {
        position: relative;
        left: auto;
        top: auto;
        margin-left: 0 !important;
        margin-top: 0;
        transform: none !important;
        font-size: 80px;
        line-height: 56px;
        letter-spacing: -0.025em;
        text-align: center;
    }
`;

const StyledGesture = styled.div`
    position: absolute;
    left: 100%;
    top: 50%;
    margin-left: 600px;
    margin-top: -90px;
    color: ${white};
    width: 140px;
    height: 140px;
    border-radius: 100%;
    background-color: #2a72f8;
    transition: transform 0.2s linear;

    ${media.tablet} {
        left: auto;
        top: 75px;
        margin-top: 0;
        transform: none !important;
        right: 50%;
        margin-left: 0 !important;
        margin-right: 237px !important;
    }

    ${media.mobile} {
        left: auto;
        top: 11px;
        margin-left: 0 !important;
        margin-top: 0;
        right: 50%;
        transform: none !important;
        margin-right: 47px !important;
        width: 56px;
        height: 56px;
    }
`;

const StyledIcon = styled(Icon)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    ${media.mobile} {
        width: 24px !important;
        height: 24px !important;

        & svg {
            width: 24px;
            height: 24px;
        }
    }
`;

const StyledPreview = styled(CharacterPreview)`
    position: absolute;
    right: calc(50% + 255px);
    top: 50%;
    transform: translate(0, -50%);

    ${media.tablet} {
        display: none;
    }

    ${media.mobile} {
        display: none;
    }
`;

export const GesturesPromoSection: React.FC<HorSectionProps> = ({ progress = 0, totalProgress = 0 }) => {
    const { t } = useTranslation('index');
    const textStart = 60;
    const textEnd = 90;
    const textProgress =
        totalProgress - textStart <= 0 ? 0 : ((totalProgress - textStart) / (textEnd - textStart)) * 100;
    const gestureStart = 60;
    const gestureEnd = 80;
    const gestureProgress =
        totalProgress - gestureStart <= 0 ? 0 : ((totalProgress - gestureStart) / (gestureEnd - gestureStart)) * 100;

    return (
        <StyledContainer style={{ width: `${progress}%` }}>
            <StyledContent>
                <StyledText style={{ transform: `translate(${-textProgress}vw, 0)` }}>{t('Жесты')}</StyledText>
                <StyledGesture
                    style={{
                        transform: `translate(${-gestureProgress}vw, 0) rotate(${
                            70 - (70 / 100) * gestureProgress
                        }deg)`,
                    }}
                >
                    <StyledIcon icon="gesture" width={64} height={64} color="#FFCD1E" />
                </StyledGesture>
                <StyledPreview preview="images/elena-preview.jpg" />
            </StyledContent>
        </StyledContainer>
    );
};
