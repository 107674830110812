import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { desktopSize, media } from 'const/tokens';
import { useTranslation } from 'next-i18next';
import { TextM, Headline3 } from '@salutejs/plasma-b2c';
import { applySpacing } from '@salutejs/plasma-core';
import { surfaceSolid01, white } from '@salutejs/plasma-tokens-b2c';
import { routesConfig } from 'configs/routes';
import { VideoPlayer } from 'components/VideoPlayer/VideoPlayer';
import { Title } from 'components/Title/Title';
import { withCdnUrl } from 'utils/static';

import { SBERMARKET_LINK } from '../../const/externalResources';
import { useLanguage } from '../../hooks/useLocale';

const StyledContainer = styled.div`
    max-width: calc(${desktopSize}px - 64px);
    margin: auto;
    margin-top: 150px;
    margin-bottom: 150px;
    background-color: #092793;

    border-radius: 32px;
    padding: 78px 64px;

    ${media.tablet} {
        border-radius: 0;
    }

    ${media.mobile} {
        border-radius: 0;
        padding: 78px 12px;
    }
`;

const StyledTitle = styled(Title)`
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 52px;
    max-width: 1176px;

    ${media.tablet} {
        max-width: 816px;
    }

    ${media.mobile} {
        text-align: center;
        width: 100%;
    }
`;

const CardsContainer = styled.div`
    display: flex;
    justify-content: space-around;

    ${media.mobile} {
        flex-direction: column;
    }
`;

const StyledCard = styled.a`
    cursor: pointer;
    color: ${white};
    max-width: 580px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 48px;
    width: 50%;
    background-color: ${surfaceSolid01};
    border-radius: 20px;
    transition: transform linear 0.2s;
    ${applySpacing}

    ${media.tablet} {
        max-width: 400px;
        padding: 32px;
    }

    ${media.mobile} {
        padding: 24px;
        width: 290px;
    }

    &:first-child {
        margin-right: 8px;
        margin-left: auto;

        ${media.mobile} {
            margin-right: auto;
        }
    }

    &:last-child {
        margin-left: 8px;
        margin-right: auto;

        ${media.mobile} {
            margin-left: auto;
        }
    }

    &:hover {
        transform: scale(1.02);
    }
`;

const StyledCardTitle = styled(Headline3)`
    ${applySpacing}
`;

const StyledCardDescription = styled(TextM)`
    hyphens: none;
`;

const StyledCardImage = styled.img`
    width: 100%;
    margin-top: -48px;

    ${media.tablet} {
        margin-top: -24px;
    }

    ${media.mobile} {
        margin-top: -16px;
    }
`;

const StyledVideoPlayer = styled(VideoPlayer)`
    margin-top: auto;
    margin-bottom: auto;
`;

export const DevelopersSection: React.FC = () => {
    const { t } = useTranslation('index');
    const lang = useLanguage();
    const isCypress = Boolean(typeof window !== 'undefined' && window.Cypress);

    return lang === 'ru' ? (
        <StyledContainer>
            <StyledTitle>{t('Инструменты для разработчиков')}</StyledTitle>

            <CardsContainer>
                <Link href={routesConfig.gameDevLanding}>
                    <StyledCard mb={8}>
                        <StyledVideoPlayer
                            fallback={<StyledCardImage src="/images/api-fallback.png" />}
                            url={withCdnUrl('video/api.mp4')}
                            playing={!isCypress}
                            loop
                            muted
                            showFullscreen={false}
                            withControls={false}
                        />
                        <StyledCardTitle mb={12}>{t('Открытый API')}</StyledCardTitle>
                        <StyledCardDescription>
                            {t('Открытый API сервиса и плагины для автоматической анимации')}
                        </StyledCardDescription>
                    </StyledCard>
                </Link>
                <StyledCard mb={8} href={SBERMARKET_LINK} target="_blank" rel="nofollow noopener">
                    <StyledCardImage src="/images/smartmarket-developers-banner.png" />
                    <StyledCardTitle mb={12}>{t('Вы разработчик?')}</StyledCardTitle>
                    <StyledCardDescription>
                        {t('Попробуйте другие технологии от Сбера на developers.sber.ru')}
                    </StyledCardDescription>
                </StyledCard>
            </CardsContainer>
        </StyledContainer>
    ) : null;
};
