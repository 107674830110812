import { tryNow } from '../../../utils/signupUtils';
import { fetchAnalytics } from '../../../utils/fetchAnalytics';

export const scrollToSignUp = tryNow((id) =>
    fetchAnalytics().then((analyticsInstance) =>
        analyticsInstance.click({
            eventAction: 'Landing:RegisterButtonClick',
            eventCategory: 'landing',
            id,
        }),
    ),
);
