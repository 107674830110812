import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { withCdnUrl } from '../../utils/static';
import { MakeItEasySection as MakeItEasySectionLayout } from '../../components/Landing/MakeItEasySection';
import { media } from '../../const/tokens';
import { Locale } from '../../typings/locale';

import { Video } from './Video';

const StyledMakeItEasySectionLayout = styled(MakeItEasySectionLayout)`
    margin-top: 112px;

    ${media.mobile} {
        margin-top: 0;
    }
`;

export const MakeItEasySection: React.FC = () => {
    const { t } = useTranslation('index');
    const router = useRouter();
    const url = router.locale === Locale.Ru ? withCdnUrl('video/makeItEasy.mp4') : withCdnUrl('video/makeItEasyEn.mp4');

    return (
        <StyledMakeItEasySectionLayout
            title={t('Сделать видео легко')}
            description={
                <ol>
                    <li>{t('Загрузите текст. Также можно добавить PDF-презентацию.')}</li>
                    <li>{t('Загрузите фон — фото или видео.')}</li>
                    <li>{t('Разместите персонажа в кадре, как вам нравится, добавьте жесты. Готово!')}</li>
                </ol>
            }
        >
            <Video url={url} />
        </StyledMakeItEasySectionLayout>
    );
};
