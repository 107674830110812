import { fetchAnalytics } from '../../../utils/fetchAnalytics';

export function trackLandingLogin(): void {
    fetchAnalytics().then((analyticsInstance) =>
        analyticsInstance.click({
            eventAction: 'Landing:LoginButtonClick',
            eventCategory: 'landing',
            id: 'landingPreview',
        }),
    );
}

export function trackLandingRegister(): void {
    fetchAnalytics().then((analyticsInstance) =>
        analyticsInstance.submit({
            eventAction: 'Landing:RegisterSubmit',
            eventCategory: 'landing',
        }),
    );
}
