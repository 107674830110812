import React, { useCallback } from 'react';
import styled from 'styled-components';
import { white } from '@salutejs/plasma-tokens-b2c';

import { Icon } from '../../components/Icon/Icon';
import { media } from '../../const/tokens';
import { Text } from '../../components/Text/Text';

const StyledIcon = styled(Icon)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &,
    svg {
        width: 150px !important;
        height: 150px !important;
    }

    ${media.tablet} {
        &,
        svg {
            width: 80px !important;
            height: 80px !important;
        }
    }

    ${media.mobile} {
        &,
        svg {
            width: 48px !important;
            height: 48px !important;
        }
    }
`;

const StyledVideoPreview = styled.div<{ preview: string }>`
    position: relative;
    width: 453px;
    height: 317px;
    margin-bottom: 16px;
    border-radius: 32px;
    outline: none;
    background: #212a31 ${({ preview }) => `url(${preview})`} no-repeat center/cover;
    background-size: cover;

    ${media.tablet} {
        width: 327px;
        height: 211px;
    }

    ${media.mobile} {
        width: 200px;
        height: 125px;
        border-radius: 16px;
    }
`;

const StyledText = styled(Text)`
    color: ${white};
`;

export const StyledVideoContainer = styled.div`
    outline: none;
`;
interface VideoPreviewCardProps {
    url: string;
    preview: string;
    title: string;
    className?: string;
    onClick(url: string): void;
}

export const VideoPreviewCard: React.FC<VideoPreviewCardProps> = ({ url, preview, title, className, onClick }) => {
    const clickHandle = useCallback(() => onClick(url), [onClick, url]);

    return (
        <StyledVideoContainer onMouseUp={clickHandle} className={className}>
            <StyledVideoPreview preview={preview}>
                <StyledIcon icon="play" color={white} />
            </StyledVideoPreview>
            <StyledText>{title}</StyledText>
        </StyledVideoContainer>
    );
};
