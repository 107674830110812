import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { fontWeights, white } from '@salutejs/plasma-tokens-b2c';
import { useTranslation } from 'next-i18next';

import { media } from '../../const/tokens';
import { Text } from '../../components/Text/Text';
import { Title } from '../../components/Title/Title';
import { CharacterPreview, HorSectionProps } from '../../components/Landing/CharacterPreview';
import { withCdnUrl } from '../../utils/static';

import { GestureButton } from './GestureButton';

const StyledContainer = styled.section`
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    background: #092793;
    overflow: hidden;
    transition: width 0.3s linear;

    ${media.tablet} {
        position: relative;
        right: auto;
        top: auto;
        height: auto;
    }

    ${media.mobile} {
        position: relative;
        right: auto;
        top: auto;
        height: auto;
    }
`;

const StyledContent = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    margin: 0 auto;
    height: 100%;

    ${media.tablet} {
        position: relative;
        right: auto;
        top: auto;
        width: auto;
        display: flex;
        flex-flow: row;
        padding: 80px 0 80px 50px;
    }

    ${media.mobile} {
        position: relative;
        right: auto;
        top: auto;
        width: auto;
        padding: 40px 0;
    }
`;

const StyledDescriptionBlock = styled.div`
    display: flex;
    flex-flow: column;
    max-width: 774px;
    height: 100%;
    margin-left: calc(50% - 255px + 108px);
    justify-content: center;
    transition: opacity 0.2s linear;

    ${media.tablet} {
        margin-left: 0;
        padding-left: 40px;
        width: calc(100% - 241px);
        height: auto;
    }

    ${media.mobile} {
        margin: 0 24px;
        width: auto;
    }
`;

const StyledDescription = styled(Text)`
    color: ${white};
    max-width: 576px;

    ${media.tablet} {
        padding-right: 50px;
    }
`;

const StyledButtonsContainer = styled.div`
    display: flex;
    flex-flow: wrap;
    margin-top: 24px;

    ${media.mobile} {
        width: 300px;

        & button:first-child {
            order: 2;
        }
    }
`;

const StyledPreview = styled(CharacterPreview)`
    position: absolute;
    right: calc(50% + 255px);
    top: 50%;
    transform: translate(0, -50%);

    ${media.tablet} {
        position: relative;
        right: auto;
        top: auto;
        transform: none;
    }

    ${media.mobile} {
        position: relative;
        right: auto;
        top: auto;
        transform: none;
        margin: 0 auto 24px;
    }
`;

const StyledMobileTitle = styled(Title)`
    display: none;
    color: ${white};
    font-weight: ${fontWeights.Medium};
    margin-bottom: 4px;

    ${media.mobile} {
        display: block;
    }
`;

export const GesturesSection: React.FC<HorSectionProps> = ({ progress = 0 }) => {
    const { t } = useTranslation('index');
    const [currentPreview, setCurrentPreview] = useState<string>();

    const pause = useCallback(() => setCurrentPreview(undefined), []);

    return (
        <StyledContainer style={{ width: `${progress}%` }} id="landingGestures">
            <StyledContent>
                <StyledPreview videoPreview={currentPreview} onPause={pause} preview="images/elena-preview.jpg" />
                <StyledDescriptionBlock style={{ opacity: progress / 100 }}>
                    <StyledMobileTitle>{t('Жесты')}</StyledMobileTitle>
                    <StyledDescription>
                        {t('Добавьте жесты, чтобы оживить видеоряд или сделать акценты.')}
                    </StyledDescription>

                    <StyledButtonsContainer>
                        <GestureButton
                            label={t('Обращение')}
                            value={withCdnUrl('video/salut_right_once.mp4')}
                            activeValue={currentPreview}
                            onClick={setCurrentPreview}
                        />
                        <GestureButton
                            label={t('Лайк')}
                            value={withCdnUrl('video/like_right1.mp4')}
                            activeValue={currentPreview}
                            onClick={setCurrentPreview}
                        />
                        <GestureButton
                            label={t('Сердечко')}
                            value={withCdnUrl('video/heart1.mp4')}
                            activeValue={currentPreview}
                            onClick={setCurrentPreview}
                        />
                    </StyledButtonsContainer>
                </StyledDescriptionBlock>
            </StyledContent>
        </StyledContainer>
    );
};
