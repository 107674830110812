import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { black, buttonWhite } from '@salutejs/plasma-tokens-b2c';
import { useTranslation } from 'next-i18next';
import { BodyM, spacing } from '@salutejs/plasma-b2c';
import { media } from 'const/tokens';
import { Title } from 'components/Title/Title';
import { Text } from 'components/Text/Text';
import { CharacterPreview, HorSectionProps } from 'components/Landing/CharacterPreview';
import { withCdnUrl } from 'utils/static';
import { useLanguage } from 'hooks/useLocale';
import { bodyS } from '@salutejs/plasma-typo';

const StyledContainer = styled.section`
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    background: ${buttonWhite};
    overflow: hidden;
    transition: width 0.3s linear;

    ${media.tablet} {
        position: relative;
        right: auto;
        top: auto;
        height: auto;
        padding: 80px 0 80px 50px;
    }

    ${media.mobile} {
        position: relative;
        right: auto;
        top: auto;
        height: auto;
        padding: 46px 0 40px;
    }
`;

const StyledContent = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    margin: 0 auto;
    height: 100%;

    ${media.tablet} {
        position: relative;
        right: auto;
        top: auto;
        width: auto;
        height: auto;
        display: flex;
        flex-flow: row;
    }

    ${media.mobile} {
        position: relative;
        right: auto;
        top: auto;
        width: auto;
        height: auto;
    }
`;

const StyledDescriptionBlock = styled.div`
    display: flex;
    flex-flow: column;
    max-width: 774px;
    height: 100%;
    margin-left: calc(50% - 255px + 108px);
    justify-content: center;
    transition: opacity 0.2s linear;

    ${media.tablet} {
        margin-left: 0;
        padding-left: 40px;
        width: calc(100% - 241px);
        height: auto;
    }

    ${media.mobile} {
        margin-left: 0;
    }
`;

const StyledTitle = styled(Title)`
    color: ${black};
    margin-bottom: ${spacing[16]};
    margin-top: -56px;

    ${media.tablet} {
        margin-top: 0;
        padding-right: 50px;
    }

    ${media.mobile} {
        margin-top: 0;
        padding: 0 ${spacing[24]};
    }
`;

const StyledDescription = styled(Text)`
    color: ${black};
    max-width: 457px;

    ${media.tablet} {
        padding-right: 50px;
    }

    ${media.mobile} {
        padding: 0 24px;
    }
`;

const StyledPreview = styled(CharacterPreview)`
    position: absolute;
    right: calc(50% + 255px);
    top: 50%;
    transform: translate(0, -50%);

    ${media.tablet} {
        position: relative;
        right: auto;
        top: auto;
        transform: none;
    }

    ${media.mobile} {
        position: relative;
        right: auto;
        top: auto;
        transform: none;
        margin: 0 auto ${spacing[24]};
    }
`;

const StyledPromoBlock = styled.div`
    margin-top: 64px;
    display: flex;
    flex-flow: row;

    ${media.mobile} {
        margin-top: ${spacing[24]};
        padding: 0 ${spacing[24]};
    }
`;

const StyledPromoBlockIcon = styled.img`
    display: block;
    margin-right: ${spacing[32]};
    width: 120px;
    height: 54px;

    ${media.mobile} {
        width: 67px;
        height: 30px;
        margin-right: ${spacing[16]};
    }
`;

const StyledPromoBlockDesc = styled(BodyM)`
    color: ${black};
    max-width: 250px;
    hyphens: none;

    ${media.mobile} {
        ${bodyS}
    }
`;

export const SberAISection: React.FC<HorSectionProps> = ({ progress = 0 }) => {
    const { t } = useTranslation('index');
    const lang = useLanguage();
    const [currentPreview, setCurrentPreview] = useState<string | undefined>();

    const pause = useCallback(() => setCurrentPreview(undefined), []);

    return (
        <StyledContainer style={{ width: `${progress}%` }} id="sberAI">
            <StyledContent>
                <StyledPreview
                    videoPreview={currentPreview}
                    onPause={pause}
                    onClick={pause}
                    preview="images/elena-preview_deepfaked.jpeg"
                />
                <StyledDescriptionBlock style={{ opacity: progress / 100 }}>
                    <StyledTitle>{t('Замена лица')}</StyledTitle>

                    <StyledDescription>
                        {t('Сделайте своих аватаров уникальными и похожими на вас — просто загрузите свое фото!')}
                    </StyledDescription>

                    {lang === 'ru' && (
                        <StyledPromoBlock>
                            <StyledPromoBlockIcon src={withCdnUrl('images/sberAILogo.svg')} />
                            <StyledPromoBlockDesc bold>
                                {t('Технология представлена подразделением развития искуственного интеллекта')}
                            </StyledPromoBlockDesc>
                        </StyledPromoBlock>
                    )}
                </StyledDescriptionBlock>
            </StyledContent>
        </StyledContainer>
    );
};
