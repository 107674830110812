import React from 'react';
import styled from 'styled-components';
import { white } from '@salutejs/plasma-tokens-b2c';
import { useTranslation } from 'next-i18next';

import { media } from '../../const/tokens';
import { withCdnUrl } from '../../utils/static';
import { Title } from '../../components/Title/Title';
import { Text } from '../../components/Text/Text';
import { AdaptiveButton } from '../../components/Button/AdaptiveButton';
import { CharacterPreview, HorSectionProps } from '../../components/Landing/CharacterPreview';

import { scrollToSignUp } from './utils/signUp';

const StyledContainer = styled.section`
    height: 100vh;

    ${media.tablet} {
        height: auto;
        padding: 58px 25px 80px 49px;
        display: flex;
        flex-flow: row;
    }

    ${media.mobile} {
        height: auto;
        padding: 46px 24px 40px;
    }
`;

const StyledContent = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    margin: 0 auto;
    height: 100%;

    ${media.tablet} {
        height: auto;
        position: relative;
        top: auto;
        right: auto;
    }

    ${media.mobile} {
        height: auto;
        position: relative;
        top: auto;
        right: auto;
        width: auto;
    }
`;

const StyledDescriptionBlock = styled.div`
    display: flex;
    flex-flow: column;
    max-width: 774px;
    height: 100%;
    margin-left: calc(50% - 255px + 108px);
    justify-content: center;
    transition: opacity 0.2s linear;

    ${media.tablet} {
        margin-left: 0;
        padding-left: 39px;
        float: left;
        width: calc(100% - 241px);
    }

    ${media.mobile} {
        margin-left: 0;
    }
`;

const StyledTitle = styled(Title)`
    color: ${white};
    margin-bottom: 8px;
    max-width: 660px;
`;

const StyledDescription = styled(Text)`
    color: ${white};
    max-width: 579px;
    margin-bottom: 24px;
`;

const StyledButton = styled(AdaptiveButton)`
    background-color: #000;
    width: 226px;

    ${media.mobile} {
        width: 100%;
    }
`;

const StyledPreview = styled(CharacterPreview)`
    position: absolute;
    right: calc(50% + 255px);
    top: 50%;
    transform: translate(0, -50%);
    transition: width 0.2s linear;

    ${media.tablet} {
        position: relative;
        width: 241px !important;
        right: auto;
        top: auto;
        transform: none;
        float: left;
    }

    ${media.mobile} {
        position: relative;
        width: 196px !important;
        right: auto;
        top: auto;
        transform: none;
        margin: 0 auto 24px;
    }
`;

const StyledHide3dWrap = styled.div`
    position: absolute;
    right: calc(50vw + 220px);
    top: 50%;
    transform: translate(0, -50%);
    transition: width 0.2s linear;
    background-color: #000;
    height: 800px;

    ${media.tablet} {
        display: none;
    }

    ${media.mobile} {
        display: none;
    }
`;

const StyledBg = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background: #092793;
    transition: width 0.3s linear;
`;

const StyledMultiPreview = styled.img`
    position: absolute;
    top: calc(50% + 36px);
    left: calc(50% + 444px);
    width: 180px;
    height: 252px;
    border-radius: 24px;
    object-fit: cover;
    transition: opacity 0.2s linear;

    ${media.tablet} {
        display: none;
    }

    ${media.mobile} {
        display: none;
    }
`;

export const WelcomeCharacterSection: React.FC<HorSectionProps> = ({ progress = 0 }) => {
    const { t } = useTranslation('index');

    return (
        <StyledContainer id="landingWelcome">
            <StyledHide3dWrap style={{ width: `${5 * progress}px` }} />
            <StyledBg style={{ width: `${progress}%` }} />
            <StyledContent>
                <StyledPreview style={{ width: `${4 * progress}px` }} preview="images/elena-preview.jpg" />
                <StyledDescriptionBlock style={{ opacity: (progress / 100) * 2 }}>
                    <StyledTitle>{t('Визуальный персонаж поможет донести ваши идеи')}</StyledTitle>

                    <StyledDescription>
                        {t('Несколько образов на выбор — разный стиль, причёска, одежда.')}
                    </StyledDescription>

                    <StyledButton data-e2e="welcomeCharacterTryBtn" onClick={scrollToSignUp}>
                        {t('Попробовать')}
                    </StyledButton>
                </StyledDescriptionBlock>
                <StyledMultiPreview
                    style={{ opacity: progress / 100 }}
                    src={withCdnUrl('images/multiElenaPreview.gif')}
                    alt="Visper speaker preview"
                />
            </StyledContent>
        </StyledContainer>
    );
};
