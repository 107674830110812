import React, { ReactElement, ReactNode, ReactNodeArray, useEffect } from 'react';

import { creteLandingIntersectionObserver } from '../utils/landingIntersectionObserver';

export const useLandingTrackViewSection = (children: ReactNode | any): void => {
    useEffect(() => {
        const observer = creteLandingIntersectionObserver();

        const childrenTraversal = (children: ReactNodeArray): void => {
            React.Children.forEach(children as ReactElement[], (child: ReactElement) => {
                if (!child) {
                    return;
                }

                if (child?.props?.children) {
                    childrenTraversal(child.props.children);
                }

                const { key } = child;

                if (!key || typeof key !== 'string') {
                    return;
                }

                const element = document.getElementById(key);

                if (element) {
                    observer.observe(element);
                }
            });
        };

        if (Array.isArray(children)) {
            childrenTraversal(children);
        }

        return () => observer.disconnect();
    }, [children]);
};
