import React, { ReactNode } from 'react';
import { GetStaticProps } from 'next';

import { withDomRender } from '../hocs/withDomRender';
import { SignupSection } from '../components/Landing/Signup';
import { Footer } from '../components/Landing/Footer';
import { LandingContainer } from '../components/Landing/LandingContainer';
import { PreviewSection } from '../pagesSpecific/landing/PreviewSection';
import { HorizontalScrollContainer } from '../pagesSpecific/landing/HorizontalScrollContainer';
import { WelcomeCharacterSection } from '../pagesSpecific/landing/WelcomeCharacterSection';
import { VoiceSection } from '../pagesSpecific/landing/VoiceSection';
import { GesturesPromoSection } from '../pagesSpecific/landing/GesturesPromoSection';
import { GesturesSection } from '../pagesSpecific/landing/GesturesSection';
import { VideoListSection } from '../pagesSpecific/landing/VideoListSection';
import { MakeItEasySection } from '../pagesSpecific/landing/MakeItEasySection';
import { useLandingTrackViewSection } from '../pagesSpecific/landing/hooks/useLandingTrackViewSection';
import { trackLandingRegister } from '../pagesSpecific/landing/utils/LandingAnalytics';
import { getPageI18nProps } from '../utils/translation';
import { DevelopersSection } from '../pagesSpecific/landing/DevelopersSection';
import { SberAISection } from '../pagesSpecific/landing/SberAISection';

const AnalyticsContainer: React.FC<{ children: ReactNode | any }> = ({ children }) => {
    useLandingTrackViewSection(children);

    return children;
};

const Landing: React.FC = () => {
    return (
        <LandingContainer>
            <AnalyticsContainer>
                <PreviewSection key="landingPreview" />
                <HorizontalScrollContainer>
                    <WelcomeCharacterSection key="landingWelcome" />
                    <SberAISection key="sberAI" />
                    <VoiceSection key="landingVoice" />
                    <GesturesPromoSection />
                    <GesturesSection key="landingGestures" />
                </HorizontalScrollContainer>
                <VideoListSection key="landingVideoList" />
                <MakeItEasySection key="landingMakeItEasy" />
                <SignupSection key="landingSignup" onSignup={trackLandingRegister} />
                <DevelopersSection />
                <Footer />
            </AnalyticsContainer>
        </LandingContainer>
    );
};

export const getStaticProps: GetStaticProps = async (context) => ({
    props: await getPageI18nProps(context, [
        'index',
        'sberId',
        'login',
        'signup',
        'validators',
        'footer',
        'authError',
        'feedbackForm',
        'authForm',
    ]),
});

export default withDomRender(Landing);
